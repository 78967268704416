import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import {  useNavigate, useParams } from "react-router-dom";

const PaypalSuccess = () => {
    const payload = JSON.parse(localStorage.getItem("payload"))
  const secretKey = "secret";
  const [msg, setMsg] = useState(1);
  const navigate = useNavigate()

  useEffect(() => {

        setMsg(1)
      console.log(payload);
      axios.post("https://adhd-backend-ef6e0887c0df.herokuapp.com/send-email", {
        email: payload?.email,
        score : payload?.score,
        first_name : payload?.first_name
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      
      
  }, [payload]);

  return (
    <div className="  lg:px-[200px] px-2  gap-10 mt-4">
      {msg === 2 && (
        <div className="relative lg:order-1 order-2">
          <div className="z-50 text-center">Token Expired</div>

          <Button
            sx={{
              textTransform: "none",
              fontWeight: "900",
              bgcolor: "#677CF5",
              color: "white",
              width: "100%",
              px: 4,
              mt: 4,
              py: 2,
              fontSize: "20px",
              ":hover": { bgcolor: "#677CF5" },
            }}
            onClick= {
                ()=> window.location.href = '/test'
            }
          >
            Take test <ArrowForward />
          </Button>

          {/* <div className="absolute top-10 left-0 w-full h-[350px] bg-[#F4F4FF] -z-10"></div> */}
        </div>
      )}

      {msg === 1 && (
        <div className="relative lg:order-1 order-2">
          <div className="z-50 text-center">Email sent</div>

          <Button
            sx={{
              textTransform: "none",
              fontWeight: "900",
              bgcolor: "#677CF5",
              color: "white",
              width: "100%",
              px: 4,
              mt: 4,
              py: 2,
              fontSize: "20px",
              ":hover": { bgcolor: "#677CF5" },
            }}
            onClick={()=> navigate('/test')}
          >
            Take another test <ArrowForward />
          </Button>

          {/* <div className="absolute top-10 left-0 w-full h-[350px] bg-[#F4F4FF] -z-10"></div> */}
        </div>
      )}
    </div>
  );
};

export default PaypalSuccess;

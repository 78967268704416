import { ArrowForward, Cancel } from '@mui/icons-material';
import { Box, Button, Modal } from '@mui/material'
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react'
import Checkout from './Checkout';

const Paymodal = ({open, setOpen, pay}) => {
    const modalStyle = {
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
      };

      
  return (
   <Modal open={open} onClose={()=> setOpen(false)}  style={modalStyle} >
   <div className='bg-white px-4 w-[90vw] lg:w-[30vw] max-h-[80vh] lg:max-h-[40vw] overflow-y-auto'>
      <div className='text-red-500 text-right cursor-pointer' onClick={()=> setOpen(false)}> <Cancel/> </div>
    <div className='flex justify-center '>
        <div className='lg:w-[30vw]'>

        {/* <Button
                  sx={{
                    textTransform: "none",
                    fontWeight: "900",
                    bgcolor: "#677CF5",
                    color: "white",
                    width: "100%",
                    px: 4,
                    mt: 2,
                    py: 1,
                    fontSize: "15px",
                    ":hover": { bgcolor: "#677CF5" },
                  }}
                  onClick={() => pay()}
                >
                  Pay with Stripe  <ArrowForward />
                </Button> */}
        <PayPalScriptProvider
                  options={{
                    "client-id":
                      "AdWbuOlEDeA4UN8nzSwPKFGGDWgz8-nyHVzlJjDZRsHwupC1qA3jjO5wB9tbaiTDvoZrLDTUKokuiQIG",
                    currency: "USD",
                    intent: "capture",
                  }}
                >
                  <Checkout />
                </PayPalScriptProvider>
        </div>
    </div>
    </div>

    
   </Modal>
  )
}

export default Paymodal
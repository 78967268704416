import {
  ArrowLeftRounded,
  ArrowRightRounded,
  Check,
  Verified,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";
import "../../certificate.css"; // Import your CSS file or include the styles inline
import Paymodal from "./Paymodal";
import TimerCountdown from "./TimeCountDown";

const Guide = ({ score, pay }) => {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false)
  const timeout = () =>{
       console.log('timeout')
  } 
  return (
    <div className="border-2 border-[rgba(0,174,173,0.5)] shadow-lg shadow-bg-[rgba(0,174,173,1) rounded-[30px] py-5 px-5 relative">
        <Paymodal open={open} setOpen={setOpen} pay={pay} />
      {step === 1 && (
        <div className="px-5 py-4 ">
          <div className="absolute top-0 right-[-1.4px]">
          <div className=" w-fit bg-[rgba(0,174,173,0.5)]  text-[#F77700] font-bold text-[13px]  rounded-tr-[30px]  py-2 px-4">
          <TimerCountdown initialTime={2 * 60}  onTimeout={timeout} />
            </div>
            </div>
          <div className="flex items-center justify-between mb-[10px] ">
            <div className="text-[15px] text-[#C6C6C6] font-bold ">
              <span className="text-[18px] text-[#00CCCC]">1</span> /2{" "}
            </div>
           <div> <span className="line-through">$99</span> <span>$1</span> </div>

            <div className="flex items-center space-x-2">
              <ArrowLeftRounded
                sx={{
                  bgcolor: "#00CCCC",
                  borderRadius: "100%",
                  color: "white",
                  cursor:'pointer'
                }}
                onClick ={ () => setStep(1)}

              />
              <ArrowRightRounded
                sx={{
                  bgcolor: "#00CCCC",
                  borderRadius: "100%",
                  color: "white",
                  cursor:'pointer'
                }}
                onClick ={ () => setStep(2)}
              />
            </div>
          </div>

          <div className=" font-semibold text-[#575455] text-[20px] mb-[20px] mt-[10px] ">
            Empower Your Journey with The Ultimate ADHD Management Guide -
            Tailor-Made Just for You!
          </div>

          <div className=" font-semibold text-[#575455] text-[13px] mb-[4px]">
            Transform how you navigate ADHD with personalized strategies and
            hand-picked resources.
            <span className="text-[#F77700]">
              "Your custom-designed ADHD Management Guide is packed with
              actionable steps, daily planners, and carefully curated tools to
              help you:
            </span>
          </div>

          <div className="mt-5 space-y-4">
            <div className="text-[14px] flex items-center">
              {" "}
              <div>
                <Check sx={{ color: "#00CC61" }} />
              </div>{" "}
              <div>Prioritize tasks and manage your time efficiently. </div>
            </div>

            <div className="text-[14px] flex items-center">
              {" "}
              <div>
                <Check sx={{ color: "#00CC61" }} />
              </div>
              <div>
                {" "}
                Reduce stress with calming techniques perfect for your unique
                needs.{" "}
              </div>
            </div>

            <div className="text-[14px] flex items-center">
              {" "}
              <div>
                <Check sx={{ color: "#00CC61" }} />
              </div>{" "}
              <div>
                {" "}
                Boost focus and productivity with science-backed methods.{" "}
              </div>
            </div>

            <div className="text-[14px] flex items-center">
              {" "}
              <div>
                <Check sx={{ color: "#00CC61" }} />
              </div>{" "}
              <div>
                {" "}
                Find support and wisdom in a community that understands.{" "}
              </div>
            </div>
          </div>

          <div className="text-[12px] text-[#00AEAD] mt-4 mb-2 text-center">
            {" "}
            Start Your Tailored ADHD Management Now - Gain Instant Access!{" "}
          </div>

          <div className="lg:flex flex justify-center mt-[20px]">
            <Button
              sx={{
                textTransform: "none",
                bgcolor: "#F77700",
                color: "white",
                px: 2,
                py: 2,
                fontSize: "16px",
                borderRadius: "15px",
                ":hover": { bgcolor: "#F77700" },
              }}
              onClick={()=> {
                setOpen(true)
              }}
            >
              Get Your Custom Guide Now
            </Button>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="px-5">
          <div className="flex items-center justify-between mb-[10px] ">
            <div className="text-[15px] text-[#C6C6C6] font-bold ">
              <span className="text-[18px] text-[#00CCCC]">2</span> /2{" "}
            </div>
            {/* <div className=" w-[100px]   text-[16px] text-center bg-[rgba(0,174,173,0.5)]  text-[#F77700] font-bold  rounded-full  py-2 px-4">
              2:59
            </div> */}

            <div className="flex items-center space-x-2">
              <ArrowLeftRounded
                sx={{
                  bgcolor: "#00CCCC",
                  borderRadius: "100%",
                  color: "white",
                  cursor:'pointer'
                }}
                onClick ={ () => setStep(1)}

              />
              <ArrowRightRounded
                sx={{
                  bgcolor: "#00CCCC",
                  borderRadius: "100%",
                  color: "white",
                  cursor:'pointer'
                }}

                onClick ={ () => setStep(2)}
              />
            </div>
          </div>

          <div className=" font-semibold text-[#575455] text-[20px] mb-[20px] mt-[10px] ">
          Enjoy exclusive access to expert-recommended apps, ADHD-tailored music playlists, engaging videos and much more!"
          </div>

          <div className=" font-semibold text-[#575455] text-[13px] mb-[4px]">
          Unlock an extensive collection of ADHD management resources, complete with a
          special selection of supplements known for supporting cognitive wellness.
          </div>

          <div className="mt-5 space-y-4">
            <div className="text-[14px] flex items-center">
              {" "}
              <div>
                <Check sx={{ color: "#00CC61" }} />
              </div>{" "}
              <div>Typically priced at $499 today we offer this complete package to you for only $7. </div>
            </div>

            <div className="text-[14px] flex items-center">
              {" "}
              <div>
                <Check sx={{ color: "#00CC61" }} />
              </div>
              <div>
              Hear from people just like you who've found a new lease on life with our guide.
              </div>
            </div>

            <div className="text-[14px] flex items-center">
              {" "}
              <div>
                <Check sx={{ color: "#00CC61" }} />
              </div>{" "}
              <div>
                {" "}
                We stand by the quality and effectiveness of our resources with a 30-day, no-questions-asked money-back guarantee.
              </div>
            </div>

          </div>

          <div className="text-[12px] text-[#00AEAD] mt-4 mb-2 text-center">
            {" "}
            Start Your Tailored ADHD Management Now - Gain Instant Access!
          </div>

          <div className="lg:flex flex justify-center mt-[20px]">
            <Button
               onClick={()=> {
                setOpen(true)
              }}
              sx={{
                textTransform: "none",
                bgcolor: "#F77700",
                color: "white",
                px: 2,
                py: 2,
                fontSize: "16px",
                borderRadius: "15px",
                ":hover": { bgcolor: "#F77700" },
              }}

            >
              Get Your Custom Guide Now
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Guide;

import { ArrowForward, ArrowLeft, Close, KeyboardArrowRight, Search } from "@mui/icons-material";
import { Button, Drawer, SwipeableDrawer } from "@mui/material";
import React from "react";

const MobileMenu = ({ open, setOpen, setOpenShareModal }) => {
  return (
    <SwipeableDrawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
      <div className="flex justify-center my-2 absolute top-2  right-4">
        <Close onClick={() => setOpen(false)} />
      </div>

      <div className="space-y-6 lg:px-5 py-5 w-[60vw] text-[16px] mt-6">
            <div 
             className={
              window.location.pathname === "/"
                ? "  text-[#ffffff]  font-semibold bg-[#F77700] py-7 px-4 cursor-pointer "
                : " hover:text-white text-[#696A9B] h font-semibold hover:bg-[#F77700] py-7 px-4 cursor-pointer  "
            }
            onClick={() => {
              window.location.pathname = "/";
            }}
           >Home</div>
            <div className={
              window.location.pathname === "/test"
                ? "  text-[#ffffff]  font-semibold bg-[#F77700] py-7 px-4 cursor-pointer "
                : " hover:text-white text-[#696A9B] h font-semibold hover:bg-[#F77700] py-7 px-4 cursor-pointer  "
            }
            onClick={() => {
              window.location.pathname = "/test";
            }}
            >Take the Test</div>
            <div className='hover:text-white text-[#696A9B] font-semibold hover:bg-[#6176F0] py-7 px-4 cursor-pointer '
             onClick={() => {
              window.location.href = 'https://adhdwell.com';
            }}
            >Learn More</div>

      </div>
    </SwipeableDrawer>
  );
};

export default MobileMenu;

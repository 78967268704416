import logo from './logo.svg';
import './App.css';
import Main from './Main';

import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51JjuQ8BSNg08N04HRm0tbS0LFgnO6vCaknh2NNqgt420Dg0s3vygC2yxjjd06QgmLgANvX6hNiDBmgVhziIq4RkJ0031S0VpwJ');


const App = () => {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <Main />
      </Elements>
    </div>
  );
};






export default App;

import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handlePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Create a PaymentIntent directly on the client side
    const { paymentIntent, error } = await stripe.confirmPayment({
      amount: 200 * 100, // amount in cents
      currency: 'usd',
    });

    if (error) {
      setError(error.message);
    } else {
      // Confirm the PaymentIntent
      const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else {
        console.log('Payment succeeded:', result.paymentIntent);
      }
    }
  };

  return (
    <div>
      <CardElement />
      <button onClick={handlePayment}>Pay $200</button>
      {error && <div>{error}</div>}
    </div>
  );
};

export default PaymentForm;

import {
  ArrowBack,
  ArrowForward,
  Circle,
  ImportContacts,
  LibraryBooks,
  Timelapse,
} from "@mui/icons-material";
import { Button, CircularProgress, InputBase, Radio } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { analytics, db } from "../../firebase";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import Certificate from "../molecule/Certificate";
import Question from "../molecule/Question";
import Questions from "../molecule/Questions";
import TimerCountdown from "../molecule/TimeCountDown";
import { Helmet } from "react-helmet";
import CheckoutForm from "../stripe/CheckoutForm";
import { logEvent } from "firebase/analytics";
import axios from "axios";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Checkout from "../molecule/Checkout";
import Guide from "../molecule/Guide";

const Test = () => {
  const [questions, setQuestions] = useState([
    {
      id: 1,
      question:
        "How often do you have difficulty sustaining your attention while doing tasks or fun activities?",
      answer: 1,
    },
    {
      id: 2,
      question:
        "How often do you find yourself overlooking details and making careless mistakes in work or during activities?",
      answer: 1,
    },
    {
      id: 3,
      question: "How often do you seem to not listen when spoken to directly?",
      answer: 1,
    },
    {
      id: 4,
      question:
        "How often do you fail to follow through on instructions and to finish schoolwork or workplace duties?",
      answer: 1,
    },
    {
      id: 5,
      question:
        "How often do you have difficulties organizing tasks and activities?",
      answer: 1,
    },
    {
      id: 6,
      question:
        "How often do you avoid, dislike, or are reluctant to engage in tasks that require sustained mental effort?",
      answer: 1,
    },
    {
      id: 7,
      question:
        "How often do you lose things necessary for tasks or activities (e.g., keys, wallet, paperwork, phone)",
      answer: 1,
    },
    {
      id: 8,
      question:
        "How often are you easily distracted by external stimuli, like unexpected sounds or events?",
      answer: 1,
    },

    {
      id: 9,
      question:
        "How often do you forget to do something important, such as paying a bill or attending an appointment?",
      answer: 1,
    },
    {
      id: 10,
      question: "How often do you feel restless or fidgety?",
      answer: 1,
    },
    {
      id: 11,
      question: "How often do you find yourself talking excessively?",
      answer: 1,
    },
    {
      id: 12,
      question:
        "How often do you interrupt or intrude on others (e.g., butt into conversations or games)?",
      answer: 1,
    },

    {
      id: 13,
      question:
        "How often do you leave your seat in situations when remaining seated is expected?",
      answer: 1,
    },

    {
      id: 14,
      question:
        "How often do you feel as though you are driven by a motor, unable to stay still for long?",
      answer: 1,
    },

    {
      id: 15,
      question:
        "How often do you have difficulty engaging in quiet leisure activities?",
      answer: 1,
    },

    {
      id: 16,
      question: "How often do you have difficulty awaiting your turn?",
      answer: 1,
    },

    {
      id: 17,
      question:
        'How often do you act as if you are "on the go" or act as if you are driven by a motor?',
      answer: 1,
    },

    {
      id: 18,
      question:
        "How often do you have difficulty playing or doing leisure activities quietly?",
      answer: 1,
    },

    {
      id: 19,
      question:
        "How often do you blurt out answers before questions have been completed?",
      answer: 1,
    },

    {
      id: 20,
      question:
        "How often do you have trouble waiting your turn in situations when turn taking is required?",
      answer: 1,
    },

    {
      id: 21,
      question:
        "How often do you find it hard to control your impulses in situations or in conversation?",
      answer: 1,
    },

    {
      id: 22,
      question:
        "How often do you have mood swings (feelings suddenly going from calm to intense excitement or irritability)?",
      answer: 1,
    },

    {
      id: 23,
      question: "How often do you feel impatient?",
      answer: 1,
    },

    {
      id: 24,
      question:
        "How often do you find yourself making quick decisions without thinking about the long-term consequences?",
      answer: 1,
    },

    {
      id: 25,
      question:
        "How often do you change jobs or have relationship problems due to issues with completing tasks or following through with commitments?",
      answer: 1,
    },
  ]);
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(1);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [step_, setStep_] = useState(1);
  const [msg, setMsg] = useState("");
  const [countries, setCountries] = useState(null)
  const handleTimeout = () => {
    finish();
  };

  const getAllCountries = async () => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const sortedCountries = data.sort((a, b) =>
      a.name.common.localeCompare(b.name.common)
    );
      setCountries(sortedCountries)
      return sortedCountries;
    } catch (error) {
      console.error('Error fetching country data:', error);
      return null;
    }
  };

  useEffect(() => {
    if (step == 1) {
      //logs event whenever user enters /test
      logEvent(analytics, "page_view", {
        page_title: "Registration form screen",
        page_location: window.location.href,
      });
    }

    getAllCountries()
  }, []);

  // useEffect(() => {
  //   if (step === 2 && !hasUnsavedChanges ) {
  //     window.onbeforeunload = function () {
  //       return "Data will be lost if you leave the page, are you sure?";
  //     };
  //   }
  // }, [step, hasUnsavedChanges]);

  const currentTimestamp = new Date().getTime();

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    country: "",
    has_paid: false,
    timestamp: currentTimestamp,
  });

  const [score, setScore] = useState({
    score: 0,
    interpretation: "",
    other_inference: "",
  });

  const pay = async () => {
    console.log(score)
    let user = JSON.parse(localStorage.getItem('user'))
    await axios
      .post("https://adhd-backend-ef6e0887c0df.herokuapp.com/pay", {
        email: user.email,
        score: score.score,
        result: score.interpretation,
        interpretation: score.other_inference,
        first_name: user.first_name,
        last_name: user.last_name,
      })
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data.url;
      })

      .catch((err)=> {
            console.log(err)
      })


  };

  const finish = async () => {
    setLoader(true);
    setStep(3);
    const result = questions.reduce((accumulator, question) => {
      return accumulator + question.answer;
    }, 0);

    let main_result = result - 25;

    let likelihood;
    if (main_result >= 0 && main_result <= 25) {
      likelihood = "Unlikely to have ADD/ADHD.";
    } else if (main_result >= 26 && main_result <= 50) {
      likelihood =
        "Some symptoms of ADD/ADHD that could be mild or situational.";
    } else if (main_result >= 51 && main_result <= 75) {
      likelihood =
        "Moderate symptoms of ADD/ADHD, suggesting further evaluation might be beneficial.";
    } else if (main_result >= 76 && main_result <= 100) {
      likelihood =
        "Strong symptoms of ADD/ADHD, indicating a high likelihood of the disorder.";
    }

    // Separate questions into inattention and hyperactivity/impulsivity categories
    const inattentionQuestions = questions.slice(0, 9); // Questions 1-9
    const hyperactivityQuestions = questions.slice(9, 18); // Questions 10-18

    // Count the number of often (4) or very often (5) scores in each category
    const inattentionCount = inattentionQuestions.filter(
      (q) => q.answer >= 4
    ).length;
    const hyperactivityCount = hyperactivityQuestions.filter(
      (q) => q.answer >= 4
    ).length;

    let presentation;

    if (inattentionCount >= 5 && hyperactivityCount < 5) {
      presentation = "Predominantly Inattentive Presentation";
    } else if (hyperactivityCount >= 5 && inattentionCount < 5) {
      presentation = "Predominantly Hyperactive-Impulsive Presentation";
    } else if (inattentionCount >= 5 && hyperactivityCount >= 5) {
      presentation =
        "Combined Presentation (Hyperactive-Impulsive Presentation &  Inattentive Presentation)";
    } else {
      presentation = "No specific presentation identified";
    }
    console.log(data.email);
    setScore({
      score: main_result,
      interpretation: likelihood,
      other_inference: presentation,
    });

    localStorage.setItem(
      "payload",
      JSON.stringify({
        // Add any additional claims or data you want in the token
        email: data.email,
        score: main_result,
        interpretation: likelihood,
        other_inference: presentation,
        first_name: data.first_name,
        last_name: data.last_name,
      })
    );

    try {
      // Find the user with the specified email

      const usersRef = collection(db, "users");

      // Check if a user with the provided email already exists
      const q = query(usersRef, where("email", "==", data.email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Assuming there's only one user with the specified email (unique emails)
        const userDoc = querySnapshot.docs[0];

        // Update the user's record with a new score
        const userRef = doc(usersRef, userDoc.id);

        await updateDoc(userRef, {
          score: main_result,
          result: likelihood,
          interpretation: presentation,
        });
        console.log(`Score updated for user with email ${data.email}`);
        logEvent(analytics, "test_completed", { test_name: "your_test_name" });
        setLoader(false);
      } else {
        console.log(`User with email ${data.email} not found`);
      }
    } catch (error) {
      console.error("Error updating score:", error.message);
    }
    console.log({
      score: main_result,
      interpretation: likelihood, 
      other_inference: presentation,
    });
  };

  function isValidEmail(email) {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const gtag = (type, eventName, options) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...options,
    });
  };

  const registerUser = async (e) => {
    setLoader(true);
    if (!isValidEmail(data.email)) {
      setMsg("pls fill in valid email address");
      setLoader(false);

      return;
    }

    if (data.gender.length < 2) {
      setMsg("pls select your gender");
      setLoader(false);

      return;
    }

    if (data.country.length < 2) {
      setMsg("pls select your country");
      setLoader(false);

      return;
    }

    if (data.first_name.length < 2 || data.last_name.length < 2) {
      setMsg("pls fill in your first and last names");
      setLoader(false);

      return;
    }
    const usersRef = collection(db, "users");

    // Check if a user with the provided email already exists
    const q = query(usersRef, where("email", "==", data.email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // User with the provided email already exists
      setLoader(false);
      setMsg("User with this email already exists");
      return;
    }

    try {
      console.log("show me some response afer this");
      const docRef = await addDoc(collection(db, "users"), data);
      console.log("Document written with ID: ", docRef.id);
      localStorage.setItem("user", JSON.stringify(data));

      //google analytics
      gtag("event", "conversion", {
        send_to: "AW-992231912/Z21vCMj60QgQ6IOR2QM",
      });
      logEvent(analytics, "sign_up", { method: "email" });
      logEvent(analytics, "start_test", { test_name: "adhd test" });
      setLoader(false);
      setStep(2);
    } catch (e) {
      console.error("Error adding document: ", e);
      setMsg('An internal error occured , pls try again later')
      setLoader(false)
    }
  };

  useEffect(() => {
    const scrollToDistance = 70; // Adjust this value to your preference

    // Check if the screen width is less than or equal to a certain threshold (e.g., 600 pixels)
    if (window.innerWidth <= 600 && step == 2) {
      window.scrollTo({
        top: scrollToDistance,
        behavior: "smooth",
      });
    }

    if (window.innerWidth > 600 && step == 2) {
      window.scrollTo({
        top: 87,
        behavior: "smooth",
      });
    }

    if (window.innerWidth <= 600 && step == 1) {
      window.scrollTo({
        top: scrollToDistance,
        behavior: "smooth",
      });
    }

    if (window.innerWidth > 600 && step == 1) {
      window.scrollTo({
        top: 180,
        behavior: "smooth",
      });
    }
  }, [step_, step]);

  return (
    <>
      {step === 1 && (
        <div className="flex justify-center w-full">
          <div className="lg:w-[60vw]  lg:py-[100px]">
            {/* <div>
            <div className="text-[40px] bg-[#a7e0e0] text-[#F77700] font-bold text-center rounded-full  py-5">
              Ready to start!
            </div>
            <div className="text-black font-normal px-[50px]   text-[17px] mt-4 ">
              <span>
                <Timelapse sx={{ fontSize: "30px", color: "#F77700" }} />{" "}
              </span>{" "}
              You have 20 minutes to finish.
            </div>

            <div className="text-black font-normal   px-[50px]  text-[17px] mt-4 ">
              <span>
                <LibraryBooks sx={{ fontSize: "30px", color: "#F77700" }} />{" "}
              </span>{" "}
              Choose the appropriate answer to best finish the question box.
            </div>

            <div className="text-black font-normal   px-[50px]  text-[17px] mt-4 ">
              <span>
                <ImportContacts sx={{ fontSize: "30px", color: "#F77700" }} />{" "}
              </span>{" "}
              You can skip questions and return back to them later.
            </div>
          </div> */}

            <div className="text-[20px] flex flex-col justify-center px-[50px] shadow-lg shadow-[rgba(0,174,173,1)] border-[2px] border-[rgba(0,174,173,0.5)]  rounded-[30px] py-4  space-y-3 order-1">
              <div className="text-[30px] bg-[rgba(0,174,173,0.5)] text-[#F77700] font-bold text-center rounded-full  py-5">
                Lets Get Started!!
              </div>

              <div className="grid lg:grid-cols-3 gap-10 pt-[30px]">
                <div className="flex items-start space-x-2">
                  <img src="/clock.png" className="w-[30px] h-[30px]" />
                  <div>
                    <div className=" text-[13px]">
                      You have 20 minutes to finish the test.
                    </div>
                  </div>
                </div>

                <div className="flex items-start space-x-2">
                  <img src="/questionmark.png" className="w-[30px] h-[30px]" />
                  <div>
                    <div className=" text-[13px]">
                      Choose the appropriate answer to best finish the question
                      box.
                    </div>
                  </div>
                </div>

                <div className="flex items-start space-x-2">
                  <img src="/send.png" className="w-[30px] h-[30px]" />
                  <div>
                    <div className=" text-[13px]">
                      You can skip questions and return back to them later.
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-black py-5 text-[15px] text-center mb-2">
                Please provide the name you want on your result, and the Email
                to send it to:
                <div className="text-[15px]">
                  {" "}
                  ( your results will be confidentially emailed to you)
                </div>
              </div>

              {msg && (
                <div className="lg:text-[12px] text-[10px] text-red-600 text-center">
                  {" "}
                  {msg}{" "}
                </div>
              )}

              <div className="lg:px-[150px] space-y-4">
                <div className="lg:flex lg:space-x-4 space-x-0 space-y-4 lg:space-y-0 items-center ">
                  <div className="w-full">
                    <InputBase
                      sx={{
                        border: "1px solid #00CCCC",
                        pl: 3,
                        py: 2,
                        width: "100%",
                      }}
                      placeholder={"First Name"}
                      value={data.first_name}
                      name='first_name'
                      onChange={(e) => {
                        setData({ ...data, first_name: e.target.value });
                      }}
                    />
                  </div>

                  <div className="w-full">
                    <InputBase
                      sx={{
                        border: "1px solid #00CCCC",
                        pl: 3,
                        py: 2,
                        width: "100%",
                      }}
                      placeholder={"Last Name"}
                      value={data.last_name}
                      name='last_name'
                      onChange={(e) => {
                        setData({ ...data, last_name: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div>
                  <InputBase
                    sx={{
                      border: "1px solid #00CCCC",
                      pl: 3,
                      py: 2,
                      width: "100%",
                    }}
                    placeholder={"Your Email"}
                    name='email'
                    value={data.email}
                    onChange={(e) => {
                      setData({ ...data, email: e.target.value });
                    }}
                  />
                </div>

                <div class="">
                  <select
                    id="gender"
                    name="gender"
                    class="mt-1 block w-full p-2 py-4 border-[1px] border-[#00CCCC] focus:outline-none focus:border-[#00CCCC] "
                    value={data.country}
                    onChange={(e) => {
                      setData({ ...data, country: e.target.value });
                    }}
                  >
                    <option className="" hidden>
                      Select Country
                    </option>
                   {countries && countries.map((item, index)=> (
                     <option value={item.name.common}>{item.name.common}</option>
                   ))}
                  </select>
                </div>

                <div class="">
                  <select
                    id="gender"
                    name="gender"
                    class="mt-1 block w-full p-2 py-4 border-[1px] border-[#00CCCC] focus:outline-none focus:border-[#00CCCC] "
                    value={data.gender}
                    onChange={(e) => {
                      setData({ ...data, gender: e.target.value });
                    }}
                  >
                    <option className="" hidden>
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="I'd rather not say">I'd rather not say</option>
                   
                  </select>
                </div>

                <div className="flex flex-col justify-center">
                  <div className="flex justify-center">
                    {!loader ? (
                      <Button
                        sx={{
                          textTransform: "none",
                          bgcolor: "#F77700",
                          color: "white",
                          px: 4,
                          mt: 4,
                          py: 2,
                          fontSize: "18px",
                          borderRadius: "15px",
                          ":hover": { bgcolor: "#F77700" },
                        }}
                        onClick={() => {
                          registerUser();
                        }}
                      >
                        Start the test Now
                      </Button>
                    ) : (
                      <CircularProgress sx={{ mt: 4, color: "#677CF5" }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="lg:px-[200px] px-2">
          <div className="flex justify-center">
            <div className=" w-fit   text-[17px] bg-[rgba(0,174,173,0.5)]  text-[#F77700] font-bold  rounded-full  py-2 px-4">
              <TimerCountdown initialTime={20 * 60} onTimeout={handleTimeout} />
            </div>
          </div>

          <Questions
            questions={questions}
            setQuestions={setQuestions}
            setStep={setStep}
            step_={step_}
            setStep_={setStep_}
          />

          {step_ === 25 ? (
            <div className="flex justify-end px-[300px]">
              {!loader ? (
                <Button
                  sx={{
                    textTransform: "none",
                    fontWeight: "900",
                    bgcolor: "white",
                    color: "#00AEAD",
                    px: 4,
                    mt: 4,
                    py: 2,
                    fontSize: "20px",
                    ":hover": { bgcolor: "white" },
                  }}
                  onClick={() => {
                    finish();
                    setHasUnsavedChanges(true);
                  }}
                >
                  Finish <ArrowForward />
                </Button>
              ) : (
                <CircularProgress sx={{ mt: 4, color: "#00AEAD" }} />
              )}
            </div>
          ) : (
            <div className="flex lg:justify-end justify-center lg:px-[100px]">
              {step_ != 1 && (
                <Button
                  sx={{
                    textTransform: "none",
                    fontWeight: "900",
                    bgcolor: "white",
                    color: "#00AEAD",
                    px: 4,
                    mt: 4,
                    py: 2,
                    fontSize: "20px",
                    ":hover": { bgcolor: "white" },
                  }}
                  onClick={() => setStep_(step_ - 1)}
                >
                  <ArrowBack /> Previous
                </Button>
              )}

              <Button
                sx={{
                  textTransform: "none",
                  fontWeight: "900",
                  bgcolor: "white",
                  color: "#00AEAD",
                  px: 4,
                  mt: 4,
                  py: 2,
                  fontSize: "20px",
                  ":hover": { bgcolor: "white" },
                }}
                onClick={() => setStep_(step_ + 1)}
              >
                Next <ArrowForward />
              </Button>
            </div>
          )}
        </div>
      )}

      {step === 3 && (
        <div>
          {/* <div className=" lg:grid lg:grid-cols-2 flex flex-col lg:px-[200px] px-2  gap-10 mt-4">
            <div className="relative lg:order-last order-last">
              <div className="z-50">

                <div className="text-sm mb-1 ">
                  Plus, enjoy exclusive access to expert-recommended apps, an
                  ADHD-tailored music playlist for improved concentration,
                  engaging video resources, and much more!"
                </div>

                <div className="text-sm mb-2 font-bold mt-2">
                   "Unlock an extensive collection of ADHD management resources,
                  complete with a special selection of supplements known for
                  supporting cognitive wellness.
                </div>

                <div className="text-sm mb-1">
                  Typically priced at $499 today we offer this complete package
                  to you for only $7!!—a small investment for a transformative
                  impact on your day-to-day life."
                </div>

                <div className="text-sm mb-1">
                  "Hear from people just like you who've found a new lease on
                  life with our guide: “I knew I had ADHD even though I was not
                  diagnosed. Knowing opened my world to all the things I can do
                  to help myself. BIG WIN" - Cayla H.
                </div>

                <div className="text-sm mb-2 mt-4">
                  <div className="font-bold">Guaranteed Results:</div>
                  "We stand by the quality and effectiveness of our resources
                  with a 30-day, no-questions-asked money-back guarantee. Your
                  purchase is entirely risk-free. If you don't see the value,
                  we'll refund you in full - that's our promise to you."
                  <div className="mt-2">
                    "Start Your Tailored ADHD Management Now - Gain Instant
                    Access!"
                  </div>
                </div>
                <div className="font-bold mt-6 text-center">
                  Get Your Full Custom ADHD Report
                </div>
                <Button
                  sx={{
                    textTransform: "none",
                    fontWeight: "900",
                    bgcolor: "#677CF5",
                    color: "white",
                    width: "100%",
                    px: 4,
                    mt: 2,
                    py: 2,
                    fontSize: "20px",
                    ":hover": { bgcolor: "#677CF5" },
                  }}
                  onClick={() => pay()}
                >
                  Pay with Stripe ($7) <ArrowForward />
                </Button>

                <PayPalScriptProvider
                  options={{
                    "client-id":
                      "AdWbuOlEDeA4UN8nzSwPKFGGDWgz8-nyHVzlJjDZRsHwupC1qA3jjO5wB9tbaiTDvoZrLDTUKokuiQIG",
                    currency: "USD",
                    intent: "capture",
                  }}
                >
                  <Checkout />
                </PayPalScriptProvider>
              </div>

            </div>
            <div className="order-first">
              <div className=" lg:hidden mb-4">
                <Certificate score={score} />
              </div>

              <div className="lg:text-lg text-md font-bold text-center mb-2">
                Empower Your Journey with The Ultimate ADHD Management Guide -
                Tailor-Made Just for You!
              </div>
              <div className="lg:text-md text-sm font-bold text-center mb-1">
                Transform how you navigate ADHD with personalized strategies and
                hand-picked resources.
              </div>

              <div className="text-sm mb-1">
                Transform how you navigate ADHD with personalized strategies and
                hand-picked resources.
              </div>

              <div className="text-sm mb-2">
                "Your custom-designed ADHD Management Guide is packed with
                actionable steps, daily planners, and carefully curated tools to
                help you:
              </div>

              <div className="space-y-4 text-sm">
                <div>
                  <Circle
                    sx={{
                      fontSize: "10px",
                      position: "relative",
                      bottom: 1,
                      color: "black",
                    }}
                  />{" "}
                  Prioritize tasks and manage your time efficiently.
                </div>
                <div>
                  <Circle
                    sx={{
                      fontSize: "10px",
                      position: "relative",
                      bottom: 1,
                      color: "black",
                    }}
                  />{" "}
                  Reduce stress with calming techniques perfect for your unique
                  needs.
                </div>
                <div>
                  <Circle
                    sx={{
                      fontSize: "10px",
                      position: "relative",
                      bottom: 1,
                      color: "black",
                    }}
                  />{" "}
                  Boost focus and productivity with science-backed methods.
                </div>
                <div>
                  <Circle
                    sx={{
                      fontSize: "10px",
                      position: "relative",
                      bottom: 1,
                      color: "black",
                    }}
                  />{" "}
                  Find support and wisdom in a community that understands.
                </div>
              </div>

              <div className="hidden lg:block">
                <Certificate score={score} />
              </div>
            </div>
          </div> */}

          <div className="lg:grid lg:grid-cols-10 flex flex-col lg:px-[290px] px-2  gap-[60px] mt-4">

            <div className="col-span-4">
              <Certificate score={score} pay={pay} />
            </div>


            <div className="col-span-6">
                  <Guide score={score} pay={pay}/> 
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default Test;

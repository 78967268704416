import { ArrowForward } from "@mui/icons-material";
import { Radio, Button } from "@mui/material";
import React, { useState } from "react";

const Question = ({
  question,
  step,
  setStep,
  answer,
  id,
  questions,
  setQuestions,
}) => {
  return (
    <>
      <div className={step === id ? "lg:grid lg:grid-cols-2 gap-10 mt-4 px-3  ": "lg:hidden lg:grid-cols-2 gap-10 mt-4 hidden"}  >
        <div className="relative bg-white shadow-lg shadow-[rgba(0,174,173,1)] rounded-xl border-2  border-[rgba(0,174,173,0.5)] lg:h-[300px] h-[170px] flex flex-col justify-center px-4">
          <span className="font-bold lg:text-[20px] text-[16px] text-[#00AEAD] mr-2 absolute top-4 ">
            Q{id}
          </span>
          <span className="text-black lg:text-[18px] text-[16px]">{question}</span>
        </div>

        <div className="lg:text-[20px] text-[16px] flex flex-col justify-center space-y-3 mt-4 lg:mt-0">
          <div>
            {" "}
            <Radio
              style={{ color: "#00AEAD" }}
              checked={answer === 1 && true}
              onClick={() => {
                if (answer === 1) {
                    setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 0 }
                        : question
                    )
                  );
                } else {
                  setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 1 }
                        : question
                    )
                  );
                }
              }}
            />{" "}
            Never
          </div>

          <div>
            {" "}
            <Radio
              style={{ color: "#00AEAD" }}
              checked={answer === 2 && true}
              onClick={() => {
                if (answer === 2) {
                    setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 0 }
                        : question
                    )
                  );
                } else {
                  setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 2 }
                        : question
                    )
                  );
                }
              }}
            />{" "}
            Rarely
          </div>

          <div>
            {" "}
            <Radio
              style={{ color: "#00AEAD" }}
              checked={answer === 3 && true}
              onClick={() => {
                if (answer === 3) {
                    setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 0 }
                        : question
                    )
                  );
                } else {
                  setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 3 }
                        : question
                    )
                  );
                }
              }}
            />{" "}
            Sometimes
          </div>


          <div>
            {" "}
            <Radio
              style={{ color: "#00AEAD" }}
              checked={answer === 4 && true}
              onClick={() => {
                if (answer === 4) {
                    setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 0 }
                        : question
                    )
                  );
                } else {
                  setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 4 }
                        : question
                    )
                  );
                }
              }}
            />{" "}
            Often
          </div>

          <div>
            {" "}
            <Radio
              style={{ color: "#00AEAD" }}
              checked={answer === 5 && true}
              onClick={() => {
                if (answer === 5) {
                    setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 0 }
                        : question
                    )
                  );
                } else {
                  setQuestions((prevQuestions) =>
                    prevQuestions.map((question) =>
                      question.id === id
                        ? { ...question, answer: 5 }
                        : question
                    )
                  );
                }
              }}
            />{" "}
            Very Often
          </div>
        </div>
      </div>

      
    </>
  );
};

export default Question;

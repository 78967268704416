

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics , logEvent } from "firebase/analytics"
import { doc, getFirestore, setDoc } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDsSvJ6S9wCRXGNRu49TrN65hpwGT_YMUw",
  authDomain: "teststorage-3250f.firebaseapp.com",
  projectId: "teststorage-3250f",
  storageBucket: "teststorage-3250f.appspot.com",
  messagingSenderId: "908815634298",
  appId: "1:908815634298:web:e7239c67289ed7912b90c3",
  measurementId: "G-FGPGBZ9P08"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
 export const analytics = getAnalytics(app);




export const db = getFirestore(app) 

import { ArrowForward, Check } from "@mui/icons-material";
import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import { useNavigate, useParams } from "react-router-dom";

const Success = () => {
  const secretKey = "secret";
  const { token } = useParams();
  const { decodedToken, isExpired } = useJwt(token);
  const [msg, setMsg] = useState(1);
  console.log(decodedToken);
  const navigate = useNavigate()
  useEffect(() => {
    const timestamp = decodedToken?.exp;

    // Convert the timestamp to milliseconds (Date works with milliseconds)
    const timestampInMillis = timestamp * 1000;

    // Create a Date object for the timestamp
    const dateFromTimestamp = new Date(timestampInMillis);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifferenceInMillis = currentDate - dateFromTimestamp;

    // Convert the time difference to seconds
    const timeDifferenceInSeconds = timeDifferenceInMillis / 1000;
    console.log(timeDifferenceInSeconds);
    if (timeDifferenceInSeconds < 1000) {
        setMsg(1)
      console.log(decodedToken);
      axios.post("https://adhd-backend-ef6e0887c0df.herokuapp.com/send-email", {
        email: decodedToken?.email,
        score : decodedToken?.score,
        first_name : decodedToken?.first_name
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      
      
   }
  }, [decodedToken]);

  return (
    <div className=" lg:px-[200px] px-2  gap-10 mt-4">
     
      {msg === 2 && (
        <div className="px-5">
        <div className="flex items-center justify-between mb-[10px] ">
          <div className="text-[15px] text-[#C6C6C6] font-bold ">
            <span className="text-[18px] text-[#00CCCC]">2</span> /2{" "}
          </div>
          <div className=" w-fit px-4   text-[16px] text-center bg-[rgba(0,174,173,0.5)]  text-[#F77700] font-bold  rounded-full  py-2 px-4">
           Token Expired
          </div>

         
        </div>

        <div className=" font-semibold text-[#575455] text-[20px] mb-[20px] mt-[10px] ">
        Sorry,  An Issue occured with your Token.
        </div>

      

      

     

        <div className="lg:flex flex justify-center mt-[20px]">
          <Button
            sx={{
              textTransform: "none",
              bgcolor: "#F77700",
              color: "white",
              px: 2,
              py: 2,
              fontSize: "16px",
              borderRadius: "15px",
              ":hover": { bgcolor: "#F77700" },
            }}
            onClick={()=> {
             window.location.href = '/test'
            }}
          >
        Take another test
          </Button>
        </div>
      </div>

          
      )}

      {msg === 1 && (
         <div className="px-5">
         <div className="flex items-center justify-between mb-[10px] ">
           <div className="text-[15px] text-[#C6C6C6] font-bold ">
             <span className="text-[18px] text-[#00CCCC]">1</span> /2{" "}
           </div>
           <div className=" w-fit px-4   text-[16px] text-center bg-[rgba(0,174,173,0.5)]  text-[#F77700] font-bold  rounded-full  py-2 px-4">
             Email Sent
           </div>

          
         </div>

         <div className=" font-semibold text-[#575455] text-[20px] mb-[20px] mt-[10px] ">
           Thank you for completing your first step to ADHD/ADD management
         </div>

       

         <div className="mt-5 space-y-4">
           <div className="text-[14px] flex items-center">
             {" "}
             <div>
               <Check sx={{ color: "#00CC61" }} />
             </div>{" "}
             <div>ADHD / ADD guide with severity level </div>
           </div>

           <div className="text-[14px] flex items-center">
             {" "}
             <div>
               <Check sx={{ color: "#00CC61" }} />
             </div>
             <div>
               {" "}
               Further helpful information on ADD/ADHD{" "}
             </div>
           </div>

           <div className="text-[14px] flex items-center">
             {" "}
             <div>
               <Check sx={{ color: "#00CC61" }} />
             </div>{" "}
             <div>
               {" "}
              check our site https://adhdwell.com.{" "}
             </div>
           </div>

           
         </div>

      

         <div className="lg:flex flex justify-center mt-[20px]">
           <Button
             sx={{
               textTransform: "none",
               bgcolor: "#F77700",
               color: "white",
               px: 2,
               py: 2,
               fontSize: "16px",
               borderRadius: "15px",
               ":hover": { bgcolor: "#F77700" },
             }}
             onClick={()=> {
              window.location.href = 'https://adhdwell.com'
             }}
           >
            Visit Home 
           </Button>
         </div>
       </div>
      )}
    </div>
  );
};

export default Success;

import { Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/layout/Header";
import Cancel from "./components/screens/Cancel";
import Home from "./components/screens/Home";
import PaypalSuccess from "./components/screens/PaypalSuccess";
import Success from "./components/screens/Success";
import Test from "./components/screens/Test";

function Main() {
  return (
    <>
      <div class="flex flex-col min-h-screen ">
        <Header />
        <div className="flex-1 lg:mt-[20px] mt-[30px] lg:mb-[100px] mb-[70px]">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/test" element={<Test />} />
              <Route path="/success/:token" element={<Success />} />
              <Route path="/cancel/:token" element={<Cancel />} />
              <Route path="/success/paypal" element={<PaypalSuccess />} />
            </Routes>
          </BrowserRouter>
        </div>

        <div className="bg-[#00AEAE] text-white   lg:px-32 px-4 lg:py-10     text-[14px] lg:text-[16px]  py-8 footer ">
          <div className="grid grid-cols-2 gap-9">
            <div>
              <div>
                <span className="text-[#ffffff] font-bold lg:text-[30px] text-[20px] ">
                  ADHD
                </span>
                <span className="text-[#E57003] font-bold lg:text-[30px] text-[20px]">
                  WELL
                </span>
              </div>

              <div  className="text-[14px] font-bold" >Copyright 2023</div>
            </div>
            <div>
            <div className="mb-7 lg:text-[28px] text-[11px] font-bold cursor-pointer hover:text-gray-200">
                Quick Links
              </div>
              <div className="mb-7 lg:text-[18px] text-[11px]  cursor-pointer hover:text-gray-200">
                Help
              </div>
              <div className="mb-7 lg:text-[18px] text-[11px]  cursor-pointer hover:text-gray-200">
                Terms and Conditions
              </div>
              <div className="mb-7 lg:text-[18px] text-[11px]  cursor-pointer hover:text-gray-200">
                Privacy Policy
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;

import { ArrowForward } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import Question from './Question'



const Questions = ({questions, setQuestions, setStep,step_, setStep_}) => {
    
 


   
  return (
   <>

 {questions.map((item,index)=> {

    return (
        <Question question={item.question} 
        step ={step_} setStep={setStep_} answer={item.answer} id={item.id} questions={questions} setQuestions={setQuestions}
        />
    )
 })}

   
   
   </>
  )
}

export default Questions
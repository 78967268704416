import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import { Menu } from "@mui/icons-material";

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <MobileMenu open={openDrawer} setOpen={setOpenDrawer} />
      <div className="flex items-center  lg:px-16 px-4 justify-between lg:justify-normal  bg-[#00AEAD] space-x-10">
        <div className="lg:py-5 py-4">
          <span className="text-[#ffffff] font-bold lg:text-[30px] text-[20px] ">
            ADHD
          </span>
          <span className="text-[#E57003] font-bold lg:text-[30px] text-[20px]">
            WELL
          </span>
        </div>

        <div className="lg:hidden">
          {" "}
          <Menu onClick={() => setOpenDrawer(true)} />{" "}
        </div>
        <div className="lg:flex hidden items-center space-x-4 lg:text-[18px] text-[14px] ">
          <div
            className={
              window.location.pathname === "/"
                ? " text-white font-normal border-b-[#F77700]  border-b-[3px] py-7 px-4 cursor-pointer "
                : " text-white font-normal hover:border-b-[#F77700] border-b-[#00AEAE] border-b-[3px] py-7 px-4 cursor-pointer "
            }
            onClick={() => {
              window.location.pathname = "/";
            }}
          >
            Home
          </div>
          <div
          className={
            window.location.pathname === "/test"
              ? " text-white font-normal border-b-[#F77700]  border-b-[3px] py-7 px-4 cursor-pointer "
              : " text-white font-normal hover:border-b-[#F77700] border-b-[#00AEAE] border-b-[3px] py-7 px-4 cursor-pointer "
          }
            onClick={() => {
              window.location.pathname = "/test";
            }}
          >
            Take the Test
          </div>
          <div className="text-white font-normal hover:border-b-[#F77700] border-b-[#00AEAE] border-b-[3px] py-7 px-4 cursor-pointer "
          onClick={() => {
            window.location.href = 'https://adhdwell.com';
          }}
          >
            Learn More
          </div>
         
        </div>
      </div>
    </>
  );
};

export default Header;

import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import { useParams } from "react-router-dom";
import Certificate from "../molecule/Certificate";
import Paymodal from "../molecule/Paymodal";

const Cancel = () => {
  const secretKey = "secret";
  const { token } = useParams();
  const { decodedToken, isExpired } = useJwt(token);
  const [msg, setMsg] = useState(1);
  const [open, setOpen] = useState(false)
  console.log(decodedToken);

  const pay = async () => {
    await axios
      .post("https://adhd-backend-ef6e0887c0df.herokuapp.com/pay", {
        email: decodedToken?.email,
        score: decodedToken?.score,
        result: decodedToken?.result,
        interpretation: decodedToken?.interpretation,
        first_name: decodedToken?.first_name,
        last_name: decodedToken?.last_name,
      })
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data.url ;
      });
  };

  useEffect(() => {
    if (decodedToken?.exp - decodedToken?.iat < 60) {
      console.log(decodedToken);
    } else {
      setMsg(2);
    }
  }, []);

  return (
    <div className="  lg:px-[200px] px-2  gap-10 mt-4">
    
      <div className="relative lg:order-1 order-2">
        <div className="z-50 text-center">Payment Cancelled</div>

        <div className="z-50">
                {/* <img
                  src="https://the-iq-institute.org/wp-content/uploads/2023/08/cert_as_postcard-1024x890.png"
                  className="w-full h-[450px] object-cover  "
                /> */}

                <Certificate score={{score : decodedToken?.score, interpretation: decodedToken?.result, other_inference: decodedToken?.interpretation}}
                pay={pay}
                />
              </div>

     
        {/* <div className="absolute top-10 left-0 w-full h-[350px] bg-[#F4F4FF] -z-10"></div> */}
      </div>
    </div>
  );
};

export default Cancel;

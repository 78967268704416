import { Verified } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";
import "../../certificate.css"; // Import your CSS file or include the styles inline
import Paymodal from "./Paymodal";

const Certificate = ({ score, pay }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="border-2 border-[rgba(0,174,173,0.5)] shadow-lg shadow-bg-[rgba(0,174,173,1) rounded-[30px] py-5 px-5" >
        <Paymodal open={open} setOpen={setOpen} pay={pay} />
      <div className="flex items-center justify-center mb-[10px]">
        <div className=" w-fit   text-[17px] bg-[rgba(0,174,173,0.5)]  text-[#F77700] font-bold  rounded-full  py-2 px-4">
          ADHD/ADD SCORE
        </div>
      </div>

      <div className="text-center font-semibold text-[17px] mb-[4px]">
        You Scored
      </div>

      <div className="text-center font-bold text-[30px] text-[#00AE3B]">
        {score.score}%
      </div>

      <div className="mt-[26px] mb-[20px]">
        <div className="flex items-start space-x-2">
          <img src="/result.png" className="w-[30px] h-[30px]" />
          <div>
            <div className="text-[18px] mb-[12px] font-bold">Results</div>
            <div className="leading-[20px] text-[15px]">
              {score.interpretation}
            </div>
          </div>
        </div>

        <div className="flex items-start space-x-2">
          <img src="/result.png" className="w-[30px] h-[30px]" />
          <div>
            <div className="text-[18px] mb-[12px] font-bold">
              Interpretation
            </div>
            <div className="leading-[20px] text-[15px]">
              {score.other_inference}
            </div>
          </div>
        </div>
      </div>

      <div className="text-[13px] text-center text-[#00AEAD]">
        Start Your Tailored ADHD Management Now - Gain Instant Access!
      </div>


      {/* <div className="lg:flex flex justify-center mt-[20px]">
        <Button
          onClick={() => setOpen(true)}
          sx={{
            textTransform: "none",
            bgcolor: "#F77700",
            color: "white",
            px: 2,
            py: 2,
            fontSize: "16px",
            borderRadius: "15px",
            ":hover": { bgcolor: "#F77700" },
          }}
        >
          Subscribe Premium
        </Button>
      </div> */}
    </div>
  );
};

export default Certificate;

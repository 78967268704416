import React, { useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Checkout = () => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [currency, setCurrency] = useState(options.currency);

  const onCurrencyChange = ({ target: { value } }) => {
    setCurrency(value);
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: value,
      },
    });
  };

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "1",
          },
        },
      ],
    });
  };
  
  const navigate = useNavigate()

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
      navigate('/success/paypal');
    });
  };

  return (
    <div className="checkout mt-4">
      {isPending ? (
        <p className="flex items-center justify-center py-4 space-x-2 text-[17px]">
          <CircularProgress sx={{ color:'#00CCCC',mr:1 }} size='20px'/> loading..
        </p>
      ) : (
        <>
        
          <PayPalButtons
            style={{ layout: "vertical" }} 
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}
          />
        </>
      )}
    </div>
  );
};

export default Checkout;

import { ImportContacts, LibraryBooks, Timelapse } from "@mui/icons-material";
import { Avatar, Button, Paper } from "@mui/material";
import React from "react";
import { useNavigate, useNavigation } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
      <div className="lg:px-36 px-2 lg:py-[80px] py-[20px]">
      <div className="grid lg:grid-cols-2 gap-4 ">
      <div className="py-2 ">
          <div className="text-black font-extrabold lg:text-[55px] text-[30px] leading-[60px] text-center lg:text-left mb-[20px]">
          Do You Have ADHD Or ADD?
          </div>
          <div className="text-black font-normal    lg:text-[18px] text-[14px] leading-[30px] text-center lg:text-left mb-[50px]">
          Find out now by taking our ADHD/ADD test that was designed by Doctors and ADHD Specialists backed by Harvard Research.
          </div>

          <div className="lg:block flex justify-center">
            <Button
              onClick={() => (window.location.pathname = "/test")}
              sx={{
                textTransform: "none",
                bgcolor: "#F77700",
                color: "white",
                px: 2,
                py: 2,
                fontSize: "16px",
                borderRadius:'15px',
                ":hover": { bgcolor: "#F77700" },
              }}
            >
              Take the test now!
            </Button>
          </div>
        </div>
        <div>
          {" "}
          <img
            src="https://i0.wp.com/the-iq-institute.org/wp-content/uploads/2023/12/home_test_collage.png?w=1024&ssl=1"
            className="object-cover w-[500px] rounded-[20px] "
          />{" "}
        </div>

      
      </div>

      <div className="lg:my-[154px] my-[80px]">
        <div className="lg:text-[55px] text-[25px] font-bold text-center  ">Know Where You Fall </div>
        <div className="lg:text-[55px] text-[25px] font-bold text-center  ">in the ADHD / ADD Range</div>

        <div className="grid lg:grid-cols-3 gap-10 mt-[46px]">
          <div className="flex items-start space-x-2">
            <img src='/clock.png' className="w-[40px] h-[40px]"/>
            <div>
              <div className="text-[18px] mb-[12px] font-bold">Brief Test</div>
              <div className="leading-[30px] text-[15px]">It only takes up to 20 minutes to finish the test.</div>
            </div>
          </div>

          <div className="flex items-start space-x-2">
            <img src='/questionmark.png' className="w-[40px] h-[40px]"/>
            <div>
              <div className="text-[18px] mb-[12px] font-bold">General Questionaire</div>
              <div className="leading-[30px] text-[15px]">We use a variety of questions suited for everyone.</div>
            </div>
          </div>

          <div className="flex items-start space-x-2">
            <img src='/send.png' className="w-[40px] h-[40px]"/>
            <div>
              <div className="text-[18px] mb-[12px] font-bold">Instant Scoring & Tips</div>
              <div className="leading-[30px] text-[15px]">You instantly receive your Test Score and get tips to help you improve your skills customized exactly for you.</div>
            </div>
          </div>
        </div>


        <div className="lg:flex flex justify-center mt-[88px]">
            <Button
              onClick={() => (window.location.pathname = "/test")}
              sx={{
                textTransform: "none",
                bgcolor: "#F77700",
                color: "white",
                px: 2,
                py: 2,
                fontSize: "16px",
                borderRadius:'15px',
                ":hover": { bgcolor: "#F77700" },
              }}
            >
              Start the test Now
            </Button>
          </div>
          
      </div>

      <div className="grid lg:grid-cols-2 gap-4 lg:mt-[100px] mt-4 px-4 ">
        <div className="lg:order-1 order-2">
          <div className="text-[#080078] font-extrabold lg:text-[50px] text-[30px] text-center lg:ext-left leading-[50px]">
          About ADHD <span className="text-[#F77700]" >WELL</span>
          </div>
         

          <div className="text-gray-500 font-normal    text-[17px] mt-4 leading-[35px] text-center lg:text-left">
           
            Founded by a team of passionate professionals in the fields of Psychology, 
            Technology, and Healthcare, ADHDWell emerged from a shared vision to make ADHD and 
            ADD understanding more accessible. Our founding team recognized the challenges many 
            face in seeking diagnosis and support for ADHD/ADD - from long wait times to access 
            healthcare professionals to the uncertainty and stigma that often surround these
             conditions. Motivated by personal experiences and professional expertise,
              we embarked on creating a user-friendly online test 
            that offers a preliminary assessment, paving the way for a better understanding of one's mental health.
          </div>

        </div>
        <div>
          {" "}
          <img src="/bg.jpeg" className="object-cover w-[500px] rounded-[50px] " />{" "}
        </div>
      </div>


      <div className="lg:mt-[150px] mt-6 px-4">
        <div className="text-[#080078] font-extrabold lg:text-[50px] text-[30px] leading-[50px] text-center mb-10">
        What Our Doctors Have to Say:
        </div>

        <div className="grid lg:grid-cols-3  gap-4 ">
          <div className="bg-white shadow-lg rounded-lg border-[2px] border-t-[#00AEAE] lg:h-[390px] h-[350px] relative ">
            <div className="px-4 pb-[60px] pt-[10px]  text-center lg:text-[15px] text-[13px]">
              <div>
                {" "}
                “Getting tested for ADHD is not just about diagnosing a
                condition; it's about unlocking a deeper understanding of your
                unique cognitive profile. This knowledge empowers you to seek
                the right support, strategies, and treatments, leading to a more
                productive and fulfilling life.”
              </div>
            </div>

            <div className="flex justify-center bg-[#00AEAE] rounded-b-lg absolute bottom-0 w-full">
              <div className="relative bottom-[50px]">
                <Avatar
                  src="/person2.jpeg"
                  sx={{
                    width: { lg: "100px", xs: "100px" },
                    height: { lg: "100px", xs: "100px" },
                  }}
                />
                <div className="mt-2 text-white lg:text-[18px] text-[16px] text-center  ">
                  {" "}
                  Dr.Fatimah{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-lg border-[2px] border-t-[#00AEAE]  lg:h-[390px] h-[350px] relative ">
            <div className="px-4 pb-[60px] pt-[10px]  text-center lg:text-[15px] text-[13px] ">
              <div>
                {" "}
                "As a healthcare professional, I strongly recommend that
                individuals experiencing symptoms like persistent inattention,
                hyperactivity, and impulsivity consider getting tested for ADHD
                / ADD. Early diagnosis and intervention can significantly
                improve quality of life and help manage ADHD /ADD effectively."
              </div>
            </div>

            <div className="flex justify-center bg-[#00AEAE] rounded-b-lg absolute bottom-0 w-full">
              <div className="relative bottom-[50px]">
                <Avatar
                  src="/person.jpeg"
                  sx={{
                    width: { lg: "100px", xs: "100px" },
                    height: { lg: "100px", xs: "100px" },
                  }}
                />
                <div className="mt-2 text-white lg:text-[18px] text-[16px] text-center ">
                  {" "}
                  Dr. Hamad{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-lg rounded-lg border-[2px] lg:h-[390px] h-[350px]  border-t-[#00AEAE] relative ">
            <div className="px-4 pb-[60px] pt-[10px]  text-center lg:text-[15px] text-[13px] flex-1">
              <div>
                {" "}
                "Testing for ADHD is a critical step towards self-awareness and
                self-management. Identification and treatment can significantly
                improve quality of life, academic and occupational achievement,
                and interpersonal relationships. It's not just about managing
                symptoms, it's about embracing your full potential."
              </div>
            </div>

            <div className="flex justify-center bg-[#00AEAE] rounded-b-lg  absolute bottom-0 w-full ">
              <div className="relative bottom-[50px]">
                <Avatar
                  src="/person3.jpeg"
                  sx={{
                    width: { lg: "100px", xs: "100px" },
                    height: { lg: "100px", xs: "100px" },
                  }}
                />
                <div className="mt-2 text-white lg:text-[18px] text-[16px] text-center ">
                  {" "}
                  Dr. Cindy Wen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
